import { store } from '@/store/store.js';
import { decodeHtml } from '@/components/utils.js';

/*
 *Site 专属方法
 */
import { jumpToAnchor, createDialog } from './utils';
import {
    logCorpDogRequest,
    logBssFodderRequest,
    siteLogDogRequest,
    logMonitor as monitorLogDogRequest,
} from '@/api/log/index.js';
import { checkFaiDomain, visited, visitTime } from '@/api/domain/index.js';
import { checkBaiduAutomaticPush } from '@/api/baidu/index.js';
import { getFileDownloadPath } from '@/api/fileDownload/index.js';
import { topTips } from './initSite';
import { logDog } from '@/site/shared/log/index.js';

import { context as globalContext } from '@jz/utils';
const LS = globalContext.LS || {};

/*
 * 前端监控挂载
 */
(function () {
    if (typeof Site === 'undefined') {
        return;
    }
    // 代码保护
    if (!Function.prototype.bindMonitor) {
        Function.prototype.bindMonitor = function () {
            return this;
        };
    }
})();
/**
 * Generate Ajax Path
 * function: genAjaxUrl
 *
 * Usage:
 *  @param Site.genAjaxUrl(url);
 */

const genAjaxUrl = function (url, isRajax = false) {
    var beg = '';
    var req = isRajax ? 'rajax/' : 'ajax/';
    if (document.location.pathname.indexOf('/manage/') >= 0) {
        beg = '../';
    } else if (document.location.pathname.indexOf('/manage_v2/') >= 0) {
        beg = '../';
    } else {
        beg = '';
    }
    beg = Site.addRequestPrefix({ newPath: '/', oldPath: beg });

    beg += req;

    // 独立环境 自动转换
    // if(Fai.top._isDevDebug && Fai.top._aroundName){
    //     return  Site.getCrossPath(req + url);
    // }

    return beg + url;
};

// 将接口转换成对接的后端的路径 ，仅对 frontEndName 和 当前独立环境域名匹配的才会转换
// 例如 : frontEndName = joman  的时候，只有含有joman域名的才会生效。 http://ry26325-6.aaa.cn.joman.dev.cc/
const getCrossPath = function (url) {
    let { backEndName, frontEndName } = Fai.top._aroundName,
        endHost = store.state.manageMode ? 'cn' : 'cc',
        begUrl,
        _manage,
        _manage_v2,
        detailJsp;
    _manage = document.location.pathname.includes('/manage/');
    (_manage_v2 = document.location.pathname.includes('/manage_v2/')),
        (detailJsp = document.location.pathname.includes('.jsp'));
    if (backEndName.length == 0 || frontEndName.length == 0) {
        begUrl =
            _manage || _manage_v2 || detailJsp
                ? `//${location.host}/${url}`
                : `//${location.host}${document.location.pathname}/${url}`;
        return begUrl;
    }

    if (!location.host.includes(`${endHost}.${frontEndName}`)) {
        begUrl =
            _manage || _manage_v2 || detailJsp
                ? `//${location.host}/${url}`
                : `//${location.host}${document.location.pathname}/${url}`;
        return begUrl;
    }
    begUrl =
        _manage || _manage_v2 || detailJsp
            ? `//${location.host.split('.')[0]}.aaa.cn.${backEndName}.dev.cc/${url}`
            : `//${location.host.split('.')[0]}.aaa.cn.${backEndName}.dev.cc${document.location.pathname}/${url}`;
    return begUrl;
};

/********************* end genAjaxUrl **************************/

// ==> 检查用户域名是否被copy以及应对操作
const checkUserDomainAction = function () {
    if (store.state.manageMode) {
        // 管理态，不进入该逻辑
        return;
    }
    checkFaiDomain({
        domain: window.location.host,
    }).then((res) => {
        if (res.success && !res.isFaiDomain) {
            // 请求成功,验证完不是Fai域名
            $('body').html('');
        }
    });
};

// 微信客服
const addQrCode = function (id) {
    var $hoverQrCodes = (id ? $(id) : $('.jz_web')).find('a[href*="script:Site.hoverQrCode"]');
    $hoverQrCodes.each(function () {
        var $hoverQrCode = $(this),
            href = $hoverQrCode.attr('href') || '',
            match = href.match(/javascript:(.+);/) || href.match(/_script:(.+);/) || [],
            code = match[1];

        if (code) {
            $hoverQrCode.attr('href', 'javascript:;');
            $hoverQrCode.attr('onmouseover', decodeHtml(code));
            $hoverQrCode.attr('onclick', decodeHtml(code) + '; return false;');
            $hoverQrCode.addClass('J_hoverQrCodeLink');
        }
    });
};

const destroyQrCode = function () {
    $('.J_hover_qrcode').remove();
};

const removeAllQrCode = function () {
    var $hoverQrCodeLink = $('.jz_project').find('.J_hoverQrCodeLink');
    var $hoverQrCode = $hoverQrCodeLink.data('_hoverQrCode');

    if ($hoverQrCode && $hoverQrCode.length) {
        $hoverQrCode.remove();
    }

    $hoverQrCodeLink.each(function () {
        $(this)
            .removeClass('J_hoverQrCodeLink')
            .removeAttr('onmouseover')
            .removeData('_hoverQrCode')
            .removeData('_hoverQrCodeTimer')
            .off('mouseover.qrcode')
            .off('mouseleave.qrcode');
    });
};

const removeQrCode = function (id) {
    var $hoverQrCodeLink = $(id).find('.J_hoverQrCodeLink');

    var $hoverQrCode = $hoverQrCodeLink.data('_hoverQrCode');
    if ($hoverQrCode && $hoverQrCode.length) {
        $hoverQrCode.remove();
    }

    $hoverQrCodeLink
        .removeClass('J_hoverQrCodeLink')
        .removeAttr('onmouseover')
        .removeData('_hoverQrCode')
        .removeData('_hoverQrCodeTimer')
        .off('mouseover.qrcode')
        .off('mouseleave.qrcode');

    if ($hoverQrCodeLink.attr('onclick').indexOf('Site.hoverQrCode') != -1) {
        $hoverQrCodeLink.attr('onclick', '');
    }
};

const hoverQrCode = function (url, target, weChatOptions) {
    if (!target) return;
    var $target = $(target);
    const isMobi = window.innerWidth <= 768 || $('#jzRoot').hasClass('jz_root_mobi');
    const dontNeedHover = $target.parents('.s_spserv__wrap').length; //悬浮客服不需要hover 出现图片的功能
    var getHoverQrCode = function () {
        return $target.data('_hoverQrCode');
    };

    // 处理动态修改微信客服图片不实时更新问题
    let targetQrCodeUrl = $target.data('_qrCodeUrl');
    if (targetQrCodeUrl === url && getHoverQrCode()) {
        return;
    }
    var delayHideTimer = null,
        readyToHideTimer = null,
        isReadyToHide = false;

    // init once
    var createHoverQrCode = function () {
        let tips = LS.wechatQRCodeMsg;
        if (weChatOptions != null) {
            tips = weChatOptions.tips;
        }
        var html = [
            "<div class='jz_hover_qrcode J_hover_qrcode'>",
            "<div class='jz_hover_qrcode_content' style='border-radius: 8px;'>",
            "<img class='jz_hover_qrcode_img' src='" + url + "'/>",
            "<p class='jz_hover_qrcode_text'>" + tips + '</p>',
            '</div>',
            "<div class='jz_hover_qrcode_arrow J_hover_qrcode_arrow'></div>",
            '</div>',
        ];
        $target.data('_hoverQrCode', $(html.join('')).appendTo('body'));
        $target.data('_qrCodeUrl', url);
    };
    var hideHoverQrCode = function () {
        getHoverQrCode().addClass('jz_hover_qrcode__leave');
        nextTick(function () {
            getHoverQrCode().addClass('jz_hover_qrcode__leave_to');
            readyToHideTimer = whenEffectReady(function () {
                getHoverQrCode().removeClass('jz_hover_qrcode__leave jz_hover_qrcode__leave_to').hide();
                $('body').off('mousewheel.qrcode');
            });
            isReadyToHide = true;
        });
    };
    var cancelHideHoverQrCode = function () {
        clearTimeout(readyToHideTimer);
        getHoverQrCode().removeClass('jz_hover_qrcode__leave jz_hover_qrcode__leave_to');
    };
    var showHoverQrCode = function () {
        const isMobi = window.innerWidth <= 768 || $('#jzRoot').hasClass('jz_root_mobi');
        if (isMobi || dontNeedHover) return;
        getHoverQrCode().show().addClass('jz_hover_qrcode__enter');
        nextTick(function () {
            getHoverQrCode().addClass('jz_hover_qrcode__enter_to');
            whenEffectReady(function () {
                getHoverQrCode().removeClass('jz_hover_qrcode__enter jz_hover_qrcode__enter_to');
            });
        });
    };
    var delayHideHoverQrCode = function () {
        delayHideTimer = setTimeout(hideHoverQrCode, 300);
        isMobi && $('.J_hover_qrcode').off('touchmove.qrcode') && $('body').removeClass('jz_body_no_scroll');
    };
    var cancelDelayHideHoverQrCode = function () {
        clearTimeout(delayHideTimer);
    };
    var posHoverQrCode = function () {
        var position = getPosition(getHoverQrCode());
        getHoverQrCode().css(position);
    };
    var delayShowHoverQrCode = function () {
        cancelDelayHideHoverQrCode();
        cancelHideHoverQrCode();

        if (isReadyToHide) {
            isReadyToHide = false;
            showHoverQrCode();
            !isMobi && posHoverQrCode();
            $('body').off('mousewheel.qrcode').one('mousewheel.qrcode', hideHoverQrCode);
        }
    };

    var nextTick = function (fn) {
        setTimeout(fn, 0);
    };
    var whenEffectReady = function (fn) {
        return setTimeout(fn, 1000);
    };

    createHoverQrCode();
    getHoverQrCode().css('display', 'none');
    getHoverQrCode()
        .off('mouseover.qrcode')
        .on('mouseover.qrcode', cancelDelayHideHoverQrCode)
        .off('mouseleave.qrcode')
        .on('mouseleave.qrcode', delayHideHoverQrCode);

    $target
        .off('mouseover.qrcode')
        .on('mouseover.qrcode', delayShowHoverQrCode)
        .off('mouseleave.qrcode')
        .on('mouseleave.qrcode', delayHideHoverQrCode)
        .off('click.qrcode')
        .on('click.qrcode', function (e) {
            const isMobi = window.innerWidth <= 768 || $('#jzRoot').hasClass('jz_root_mobi');
            const originOverflow = document.body.style.overflow;
            if (!isMobi || $('.jz_click_qrcode').length) return;
            Vue.nextTick(() => {
                let customQrCodeMsg = $(e.currentTarget).attr('data-content') || LS.wechatQRCodeMsg;
                if (weChatOptions != null) {
                    customQrCodeMsg = weChatOptions.tips;
                }
                const popup = Site.popupBox({
                    htmlContent: `
						<div class="jz_click_qrcode J_hover_qrcode jz_hover_qrcode__enter jz_hover_qrcode__enter_to jz_click_qrcode">
							<div class="jz_click_qrcode_content">
								<img
									class="jz_click_qrcode_img"
									src="${url}"
								>
								<p class="jz_click_qrcode_text">${customQrCodeMsg}</p>
							</div>
						</div>
					`,
                    width: 250,
                    height: 280,
                    extClass: 'jz_click_qrcode_container',
                    maskClosable: true,
                    onMaskClose() {
                        document.body.style.overflow = originOverflow;
                    },
                    closeFunc: function () {},
                });
                const $popupBg = $(`#popupBg${popup[0].id.slice(8)}`);
                popup.css({
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '8px',
                });

                $popupBg.css({
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    transition: 'all 0.3s',
                    background: 'black',
                    zIndex: 9033,
                });
                // prevent scroll in mobile
                popup.on('touchmove', (e) => e.preventDefault(), false);
                $popupBg.on('touchmove', (e) => e.preventDefault(), false);

                document.body.style.overflow = 'hidden';
                $popupBg.appendTo($('.jz_web_content'));
            });
        });

    if (isMobi) {
        // $(".J_hover_qrcode").on("touchmove.qrcode",function(event){
        //     event.preventDefault();
        //     event.stopPropagation();
        // });
        // $("body").addClass("jz_body_no_scroll");
        // $(".J_hover_qrcode").click(function(){
        //     delayHideHoverQrCode();
        //     $(".J_hover_qrcode").off("touchmove.qrcode");
        //     $("body").removeClass("jz_body_no_scroll");
        // });
    }

    // first
    showHoverQrCode();
    !isMobi && posHoverQrCode();

    function getPosition($qrCode) {
        var DISTANCE = 10;

        var targetOffset = $(target).offset(),
            targetWidth = $(target).width(),
            targetHeight = $(target).height(),
            qrCodeWidth = $qrCode.width(),
            qrCodeHeight = $qrCode.height();
        var qrCodeLeft = targetOffset.left + targetWidth / 2 - qrCodeWidth / 2,
            qrCodeTop = targetOffset.top - qrCodeHeight - DISTANCE - $(document).scrollTop();

        // 边界处理
        var minTop = 0;
        if (qrCodeTop < minTop) {
            qrCodeTop += DISTANCE + targetHeight + DISTANCE + qrCodeHeight;
            $qrCode
                .find('.J_hover_qrcode_arrow')
                .addClass('jz_hover_qrcode_arrow__top')
                .removeClass('jz_hover_qrcode_arrow__bottom');
        } else {
            $qrCode
                .find('.J_hover_qrcode_arrow')
                .removeClass('jz_hover_qrcode_arrow__top')
                .addClass('jz_hover_qrcode_arrow__bottom');
        }

        var minLeft = 0,
            maxLeft = $(window).width();
        if (qrCodeLeft < minLeft) {
            qrCodeLeft = minLeft;
        }
        if (qrCodeLeft + qrCodeWidth > maxLeft) {
            qrCodeLeft = maxLeft - qrCodeWidth;
        }

        return {
            left: qrCodeLeft,
            top: qrCodeTop,
        };
    }
};

/*禁用右键，同时禁目复制、剪切 (管理态开放，且访客态去掉粘贴paste 20170125)*/
const closeRightClickTool = function () {
    $(document).bind('contextmenu copy cut', function (event) {
        if ($(event.target).is('input, textarea')) {
            return;
        } else {
            return false;
        }
    });
    if (Fai.isIE6() || Fai.isIE7() || Fai.isIE8()) {
        $(document).bind('selectstart', function (event) {
            if ($(event.target).is('input, textarea')) {
                return;
            } else {
                return false;
            }
        });
    }
};

//限制免费版的UV访问
const limitUv = async function () {};

const limitDayUv = function () {};

/**
 * 统计企业行为 by jser 2018-11-20
 * @param  {Number} dogId  description
 * @param  {Number} dogSrc description
 */

const logCorpDog = function (dogId, dogSrc) {
    logCorpDogRequest({
        dogId: dogId,
        dogSrc: dogSrc,
    });
};

const logBssFodder = function (logBssFodder) {
    logBssFodderRequest({
        logBssFodder: $.toJSON(logBssFodder),
    });
};

const ajaxLogDog = function () {
    siteLogDogRequest({})
        .then(() => {})
        .catch(() => {
            throw new Error('日志上传异常，请稍候重试');
        });
};

/**
 * total  用户site stat
 * function: total
 */

const total = function (options) {
    var defaults = {
        colId: -1,
        pdId: -1,
        ndId: -1,
    };
    var opt = $.extend({}, defaults, options);

    if (opt.colId == null || opt.colId == '' || opt.colId < 0) {
        //栏目ID
        opt.colId = -1;
    }
    if (opt.pdId == null || opt.pdId == '' || opt.pdId < 0) {
        //产品ID
        opt.pdId = -1;
    }
    if (opt.ndId == null || opt.ndId == '' || opt.ndId < 0) {
        //新闻ID
        opt.ndId = -1;
    }
    var visitEquipment = '',
        u = navigator.userAgent;
    if (!u.match(/AppleWebKit.*Mobile.*/) && !Fai.isIpad()) {
        visitEquipment = 'pc';
    } else if (Fai.isIpad()) {
        visitEquipment = 'ipad';
    } else if (Fai.isAndroid()) {
        visitEquipment = 'android';
    } else if (Fai.isIphone()) {
        visitEquipment = 'iphone';
    } else {
        visitEquipment = 'other';
    }
    visited({
        colId: opt.colId,
        pdId: opt.pdId,
        ndId: opt.ndId,
        browserType: Fai.getBrowserType(),
        screenType: Fai.getScreenType(Fai.Screen().width, Fai.Screen().height),
        sc: opt.sc,
        rf: Fai.encodeUrl(opt.rf || document.referrer),
        visitUrl: Fai.encodeUrl(document.location.href),
        visitEquipment: visitEquipment,
        statId: opt.statId,
    });
};

//百度自动推送js
const printBaiduPushJs = function () {
    checkBaiduAutomaticPush({}).then((result) => {
        if (result.printBaiduPushJs) {
            var bp = document.createElement('script');
            var curProtocol = window.location.protocol.split(':')[0];
            if (curProtocol === 'https') {
                bp.src = '//zz.bdstatic.com/linksubmit/push.js';
            } else {
                bp.src = '//push.zhanzhang.baidu.com/push.js';
            }
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(bp, s);
        }
    });
};

//收集浏览器信息
const sendBrowerInfo = function (_designMode) {
    var dogId = 0;
    if (_designMode) {
        dogId = 200603;
    } else {
        dogId = 200604;
    }

    if (Fai.isChrome()) {
        logDog(dogId, 1);
    } else if (Fai.isIE11()) {
        logDog(dogId, 2);
    } else if (Fai.isMozilla()) {
        logDog(dogId, 3);
    } else if (Fai.isSafari()) {
        logDog(dogId, 4);
    } else if (Fai.isIE9()) {
        logDog(dogId, 5);
    } else if (Fai.isIE10()) {
        logDog(dogId, 6);
    } else if (Fai.isOpera()) {
        logDog(dogId, 7);
    } else if (Fai.isIE7()) {
        logDog(dogId, 8);
    } else if (Fai.isIE8()) {
        logDog(dogId, 9);
    } else if (Fai.isAppleWebKit()) {
        logDog(dogId, 10);
    } else if (Fai.isIE6()) {
        logDog(dogId, 12);
    } else {
        logDog(dogId, 11);
    }
};

const siteStatVisitTime = function (statId) {
    visitTime({
        statId: statId,
    });
};

const fileDownload = function (ide) {
    // 链接控件--文件下载
    let params = {
        ide,
        url: window.location.pathname + window.location.search + window.location.hash,
    };
    getFileDownloadPath({
        ...params,
    }).then((result) => {
        if (result.success) {
            let file = result.file,
                {
                    allowDownload,
                    downloadUrl,
                    memberLevelIsLow,
                    visitorMemberAcct,
                    levelLimitName,
                    allowFileDownload,
                    memberId,
                    memberGroupIsOk,
                    memberOnlyLevel,
                    memberOnlyLevelIsOk,
                } = file;

            if (allowDownload) {
                // 下载文件
                if (downloadUrl) {
                    window.open(downloadUrl, '_self');
                } else {
                    Fai.ing('当前文件已丢失', true);
                }
                // downloadUrl ? window.open(downloadUrl, '_self') : Fai.ing('当前文件已丢失', true);
            } else {
                if (allowFileDownload == 1 && !memberOnlyLevel && memberLevelIsLow && memberId > 0) {
                    Fai.ing(Fai.format(LS.memberDownloadLevelLimit, visitorMemberAcct, levelLimitName), true);
                } else if (allowFileDownload == 1 && memberOnlyLevel && !memberOnlyLevelIsOk && memberId > 0) {
                    Fai.ing(`亲爱的${visitorMemberAcct}，您暂无权限下载该文件。`, true);
                } else if (allowFileDownload == 2 && !memberGroupIsOk && memberId > 0) {
                    Fai.ing(`亲爱的${visitorMemberAcct}，您暂无权限下载该文件。`, true);
                } else {
                    Fai.ing('请先登录或注册成为会员。', true);
                    window.open(downloadUrl, '_self');
                }
            }
        }
    });
};

// 做个保护（测试把带有这个链接的site转换成自适应网址，就会报错）
const jumpToModulePosition = function () {};

// 迁移site多语言自动跳转弹窗
const jumpToTargetLanguage = function (tips, url, confirmTips = '确 定', cancelTips = '取 消') {
    createDialog({
        content: tips,
        confirmButtonText: confirmTips,
        cancelButtonText: cancelTips,
        onConfirm() {
            $.cookie('_choseLanguage', 'true', { expires: 365, path: '/' });
            if (url == Fai.top.window.location.href) {
                Fai.top.widow.location.reload;
            } else {
                Fai.top.window.location.href = url;
            }
        },
        onCancel() {
            $.cookie('_choseLanguage', 'false', { expires: 365, path: '/' });
        },
        showClose: true,
    });
};

/**
 * [audioAutoPlay 微信端监听事件 触发自动播放]
 * @function audioAutoPlay
 * @param myVideo  {Object}    [myVideo description]
 *
 */

const audioAutoPlay = function (myVideo) {
    if (!myVideo) throw new Error('找不到视频');
    document.addEventListener(
        'WeixinJSBridgeReady',
        function () {
            playVideo(myVideo);
        },
        false
    );
    function playVideo(player) {
        if (Array.isArray(player)) {
            for (var videos of player) {
                if (videos.paused) {
                    videos.play();
                }
            }
        }
    }
};

// 分销访客态免费域名使用第三方代码给提示
const showTopTips = function (type, output) {
    var tip = '';
    if (type == undefined || type == 0) {
        tip =
            "<a class='safeModeTipsText' href ='javascript:;'>免费域名不支持调用第三方代码，请使用已购买的域名进行访问</a>";
    } else if (type == 1) {
        if (store.state.manageMode && Fai.top.httpInCodeCount > 0) {
            if ($('.httpInCodeTips').length > 0) {
                $('.httpIncode').text('点击定位链接位置（' + Fai.top.httpInCodeCount + '条）');
                return;
            } else {
                // <a class=\'httpIncode\' style=\'text-decoration: underline;color:#ff1d1d;\' href=\'javascript:;\' onclick=\'Site.jumptoHttpInCodeModule();\'>点击定位链接位置（' + Fai.top.httpInCodeCount + '条）</a> 自适应还没做这功能
                tip =
                    "<div class='httpInCodeTips' style='color:#ff8e1e;font-size:13px;text-align:0;'>网站内插入的代码存在非HTTPS链接，该链接无法正常加载，请先修改或者删除该链接。<a class='tipsDescription' onclick='Site.jumptoHttpInCodeExplain();' style='cursor:pointer; color:red; margin-left:40px; text-decoration:underline;'>详细说明&gt;&gt;</a></div>";
            }
        } else {
            tip =
                "<div style='color:#ff8e1e;font-size:13px;text-align:0;'>网站内插入的代码存在非HTTPS链接，该链接无法正常加载显示，请替换成HTTPS链接或使用非HTTPS方式访问</div>";
        }
    } else if (type == 2) {
        tip = "<div style='color:#ff8e1e;font-size:13px;text-align:0;'>免费域名不支持插件代码</div>";
    } else if (type == 3) {
        var urlForBindDevDomain = '//' + store.state.homeHost + '/marketing/domain.html'; //绑定独立域名
        var urlForApplyFaiscoAuth = '//' + store.state.portalHost + '/portal.jsp#appId=setAuth'; //申请凡科认证
        tip =
            '<div style=\'color:#ff8e1e;font-size:13px;text-align:0;\'>基于安全原因，2018年4月11号起新注册的用户免费域名访问不支持插件代码和第三方代码，请<a style="color:#5874d8;" onclick="logDog(200848, 5)" href="' +
            urlForBindDevDomain +
            '" target="_blank">绑定独立域名进行访问</a>或<a style="color:#5874d8;"  onclick="logDog(200848, 2)" href="' +
            urlForApplyFaiscoAuth +
            '" target="_blank">申请凡科认证</a></div>';
    }

    if (typeof output === 'string' && output != '') {
        tip = output;
    }

    topTips.addTopTips(tip);
};

//当前如果传入的moduleId为-1,则代表的是底部footer, 如果传入的moduleId为0,则代表的是设置->高级设置
const statisticsHttpInCode = function (moduleId, count) {
    if (!Fai.top.httpInCode) {
        Fai.top.httpInCode = {};
    }
    Fai.top.httpInCode[moduleId] = count;

    Fai.top.httpInCodeModuleList = [];
    Fai.top.httpInCodeCount = 0;

    for (var key in Fai.top.httpInCode) {
        if (Fai.top.httpInCode[key] == 0) {
            continue;
        }
        Fai.top.httpInCodeModuleList.push(key);
        Fai.top.httpInCodeCount += Fai.top.httpInCode[key];
    }
    if (Fai.top.httpInCodeCount == 0) {
        if ($('.httpIncode').length > 0) {
            $(document).ready(function () {
                window.location.reload();
            });
        }
    } else {
        // $('.httpIncode').text('点击定位链接位置（' + Fai.top.httpInCodeCount + '条）');
    }
};

const jumptoHttpInCodeExplain = function () {
    var url = Site.newGetBlogUrl(11673, 844);
    Fai.top.window.open(url);
};

const newGetBlogUrl = function (blogId, oemBlogId) {
    const isDebug = store.state.debugMode;
    const flag = isDebug ? 'created=true' : '';
    if (!store.state.oem) {
        return `//${store.state.jzProUrl}/blog/` + blogId + `${isDebug ? '?' + flag : ''}`;
    }
    return '//' + store.state.portalHost + '/sys/view.jsp?id=' + oemBlogId + `${isDebug ? '&' + flag : ''}`;
};

const customSearchResultToLogin = function (target) {
    let href = $(target).attr('href');
    if (!href) href = '/';
    const backUrl = encodeURIComponent(window.location.href);
    window.location.href = `${href}?url=${backUrl}`;
    if (event) {
        event?.stopPropagation();
        event?.preventDefault();
    }
};

/**
 * 重写document.write实现无阻塞加载script
 * 针对使用write方法来插入元素的第三方脚本
 * @param elem { Object }
 * @param parent { Object }  插入位置
 * @param callback { function }  回调
 */

const loadScript = function (elem, parent, callback) {
    try {
        var url = elem.src,
            _parent = parent || elem.parentNode,
            // 缓存原生的document.write
            docWrite = document.write,
            // 创建一个新script来加载
            script = document.createElement('script');
        // 重写document.write
        document.write = function (text) {
            if (!/script/.test(text)) {
                $(text).length && _parent.insertBefore($(text)[0], _parent.firstChild);
                !$(text).length && (_parent.innerText = text);
            } else {
                const _script = document.createElement('script'),
                    _url = $(text).attr('src');
                _script.type = 'text/javascript';
                _script.src = _url;
                loadScriptEvent(_script, callback);
                _parent.insertBefore(_script, _parent.firstChild);
            }
        };
        script.type = 'text/javascript';
        script.src = url;
        script.setAttribute('id', elem.id);
        // eslint-disable-next-line no-inner-declarations
        function loadScriptEvent(ele, fn) {
            ele.onerror =
                ele.onload =
                ele.onreadystatechange =
                    function (e) {
                        e = e || window.event;
                        if (!ele.readyState || /loaded|complete/.test(ele.readyState) || e === 'error') {
                            if (/loaded|complete/.test(ele.readyState) && typeof fn === 'function') {
                                fn();
                            }
                            // 恢复原生的document.write
                            document.write = docWrite;
                            setTimeout(() => {
                                // 延时移除 避免第三方脚本报错
                                //head && head.removeChild(ele);
                                // 卸载事件和断开DOM的引用
                                // 尽量避免内存泄漏
                                _parent = elem = ele = ele.onerror = ele.onload = ele.onreadystatechange = null;
                            }, 500);
                        }
                    };
        }
        loadScriptEvent(script);

        // 加载script
        _parent.insertBefore(script, _parent.firstChild);
    } catch (error) {
        console.log(error);
    }
};

/**
 * 从rjs/comMethods.src.js 迁移
 * @function name - preprocessUrl
 * @param { Object } { path, oldPath, isUsePath = true, useNew = false }
 */

const preprocessUrl = function ({ path, oldPath, isUsePath = true, useNew = false }) {
    const isOpen = !!store.state.curLanCode;
    const hasLanCode = isOpen && window.location.pathname.indexOf('/' + store.state.curLanCode + '/') !== -1;

    if (!useNew) {
        if (!isUsePath || !isOpen) {
            return oldPath;
        } else {
            if (hasLanCode) {
                return '/' + store.state.curLanCode + '/' + path.replace(/^\/+/, '');
            } else {
                return '/' + path.replace(/^\/+/, '');
            }
        }
    } else {
        if (hasLanCode) {
            return '/' + store.state.curLanCode + '/' + path.replace(/^\/+/, '');
        } else {
            return '/' + path.replace(/^\/+/, '');
        }
    }
};

const logMonitor = function (monitorId, isLog) {
    monitorLogDogRequest({
        id: Fai.encodeUrl(monitorId),
        isLog: isLog ? isLog : '',
    });
};

//跳转到指定标签项
const createUIEvent = (eventType) => {
    let event = null;
    if (Fai.isIE()) {
        event = document.createEvent('HTMLEvents');
        event.initEvent(eventType, false, true);
    } else {
        event = new Event(eventType);
    }
    return event;
};
const jumpTabpack = function ({ moduleId, tabId, colId, colPath }) {
    jumpToAnchor(colId, `module_${moduleId}_tab_${tabId}`, colPath).then(() => {
        let $tabpack = $(`#module${moduleId} .m_tabpack_wrap`);
        if ($tabpack.length > 0) {
            let triggerTypes = $tabpack.hasClass('j_tappack__switchevent-click')
                ? ['mousedown', 'click']
                : ['mouseover'];

            let $tabItem = $tabpack.find(`.j_tabitem_${tabId}`);
            if ($tabItem.length > 0 && $tabItem.hasClass('j_tab_active') === false) {
                // active 的状态不需要更改
                for (let triggerType of triggerTypes) {
                    $tabItem[0].dispatchEvent(createUIEvent(triggerType));
                }
            }
        }
    });
};
export {
    genAjaxUrl,
    getCrossPath,
    checkUserDomainAction,
    addQrCode,
    destroyQrCode,
    removeAllQrCode,
    removeQrCode,
    hoverQrCode,
    closeRightClickTool,
    limitUv,
    limitDayUv,
    logDog,
    logCorpDog,
    logBssFodder,
    logMonitor,
    ajaxLogDog,
    total,
    printBaiduPushJs,
    sendBrowerInfo,
    siteStatVisitTime,
    fileDownload,
    jumpToModulePosition,
    jumpToTargetLanguage,
    audioAutoPlay,
    showTopTips,
    statisticsHttpInCode,
    jumptoHttpInCodeExplain,
    newGetBlogUrl,
    customSearchResultToLogin,
    loadScript,
    preprocessUrl,
    jumpTabpack,
};
